<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <!--<vuexy-logo/>-->

        <b-img :src="appLogoImageFullWhite" alt="logo" style="width: 42%; margin-left: 0px" />

        <!--
        <h2 class="brand-text text-primary ml-1">
          Bruna2
        </h2>
        -->
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" style="margin: auto; contain: layout">
        <div class="w-100" style="transform: scale(1.2)">
          <b-carousel id="carousel-interval" no-hover-pause :interval="6000">
            <b-carousel-slide :img-src="require('@/assets/images/pages/BG01.jpg')" />
            <b-carousel-slide :img-src="require('@/assets/images/pages/BG02.jpg')" />
            <b-carousel-slide :img-src="require('@/assets/images/pages/BG03.jpg')" />
            <b-carousel-slide :img-src="require('@/assets/images/pages/BG04.jpg')" />
          </b-carousel>
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title class="mb-1 font-weight-bold" title-tag="h2">
            {{ t("Welcome!") }}
          </b-card-title>
          <b-card-text class="mb-2">
            {{ t("Login to your account and start using Bruna") }}
          </b-card-text>

          <!--
          <b-alert
            variant="primary"
            show
          >
            <div class="alert-body font-small-2">
              <p>
                <small class="mr-50"><span class="font-weight-bold">Admin:</span> admin@demo.com | admin</small>
              </p>
              <p>
                <small class="mr-50"><span class="font-weight-bold">Client:</span> client@demo.com | client</small>
              </p>
            </div>
            <feather-icon
              v-b-tooltip.hover.left="'This is just for ACL demo purpose'"
              icon="HelpCircleIcon"
              size="18"
              class="position-absolute"
              style="top: 10; right: 10;"
            />
          </b-alert>
            -->

          <!-- form -->
          <validation-observer ref="loginForm" #default="{ invalid }">
            <b-form class="auth-login-form mt-2" @submit.prevent="login">
              <!-- email -->
              <b-form-group label="Email" label-for="login-email">
                <validation-provider #default="{ errors }" name="Email" vid="email" rules="required|email">
                  <b-form-input id="login-email" v-model="userEmail" :state="errors.length > 0 ? false : null"
                    name="login-email" placeholder="john@example.com" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                  <!--
                  <b-link :to="{ name: 'auth-forgot-password' }">
                    <small>Forgot Password?</small>
                  </b-link>
                  -->
                </div>
                <validation-provider #default="{ errors }" name="Password" vid="password" rules="required">
                  <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                    <b-form-input id="login-password" v-model="password" :state="errors.length > 0 ? false : null"
                      class="form-control-merge" :type="passwordFieldType" name="login-password" placeholder="Password" />
                    <b-input-group-append is-text>
                      <feather-icon class="cursor-pointer" :icon="passwordToggleIcon" @click="togglePasswordVisibility" />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <!--
              <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  Remember Me
                </b-form-checkbox>
              </b-form-group>
            -->
              <!-- submit buttons -->
              <b-button type="submit" variant="primary" block :disabled="invalid">
                {{ t("Sign in") }}
              </b-button>
            </b-form>
          </validation-observer>

          <!--
          <b-card-text class="text-center mt-2">
            <span>New on our platform? </span>
            <b-link :to="{ name: 'auth-register' }">
              <span>&nbsp;Create an account</span>
            </b-link>
          </b-card-text>
-->
          <!-- divider -->
          <!--
          <div class="divider my-2">
            <div class="divider-text">
              or
            </div>
          </div>
-->

          <!-- social buttons -->
          <!--
          <div class="auth-footer-btn d-flex justify-content-center">
            <b-button
              variant="facebook"
              href="javascript:void(0)"
            >
              <feather-icon icon="FacebookIcon" />
            </b-button>
            <b-button
              variant="twitter"
              href="javascript:void(0)"
            >
              <feather-icon icon="TwitterIcon" />
            </b-button>
            <b-button
              variant="google"
              href="javascript:void(0)"
            >
              <feather-icon icon="MailIcon" />
            </b-button>
            <b-button
              variant="github"
              href="javascript:void(0)"
            >
              <feather-icon icon="GithubIcon" />
            </b-button>
          </div>
          -->
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import
{
  BCarousel,
  BCarouselSlide,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BAlert,
  VBTooltip,
} from "bootstrap-vue";
import useClientsService from "@/auth/clients/useClientsService";

import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";


import { useUtils as useI18nUtils } from "@core/libs/i18n";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { $themeConfig } from "@themeConfig";
import * as Sentry from "@sentry/vue";

export default {
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    BCarousel,
    BCarouselSlide,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    VuexyLogo,
    ValidationProvider, 
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],

  setup(props)
  {
    const { t } = useI18nUtils();

    // App Name
    const { appName, appLogoImageFull, appLogoImageFullWhite } =
      $themeConfig.app;

    return {
      // i18n
      t,

      appName,
      appLogoImageFull,
      appLogoImageFullWhite,
    };
  },

  mounted()
  {
    //console.log(useClientsService);
  },

  data()
  {
    return {
      status: "",
      password: "",
      userEmail: "",
      sideImg: require("@/assets/images/pages/login-v2.svg"),

      // validation rules
      required,
      email,
    };
  },
  computed: {
    passwordToggleIcon()
    {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl()
    {
      if (store.state.appConfig.layout.skin === "dark")
      {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/login-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    login()
    { 

      let self = this

      this.$refs.loginForm.validate().then((success) =>
      {

        if (success)
        {
          
          grecaptcha.ready(function ()
          {
            grecaptcha.execute('6LeTkugmAAAAAGMlxbwQX7-HLdpMnEI4OFNGp2ni', { action: 'login' }).then(function (recaptcha_token)
            {

              console.log({ recaptcha_token })


              // Add your logic to submit to your backend server here.

              useClientsService
                .login({
                  email: self.userEmail,
                  password: self.password,
                  recaptcha_token: recaptcha_token
                })
                .then((response) =>
                {
                  useClientsService.setToken(response.data.access_token);
                  useClientsService.setExpirationDate(response.data.expires_in);
                })
                .then(() =>
                {
                  return useClientsService.me();
                })
                .then((response) =>
                {
                  //console.log(response)

                  let user_data = response.data.data;


                  Sentry.setUser({
                    id: user_data.id,
                    ip_address: "{{auto}}",
                    email: user_data.email,
                    username: user_data.name,
                    name: user_data.name,
                    client: user_data.client.name,

                  });

                  self.$gtag.config({
                    'user_id': user_data.id
                  })


                  localStorage.setItem("userData", JSON.stringify(user_data));
                  self.$ability.update(user_data.user_abilities["BrunaFront"]);

                  self.$router.replace({ path: "/" }).then(() =>
                  {
                    self.$toast({
                      component: ToastificationContent,
                      position: "top-right",
                      props: {
                        title: `Bienvenido`,
                        icon: "CoffeeIcon",
                        variant: "secondary",
                        text: `Te conectaste exitosamente. Ahora puedes comenzar a explorar!`,
                      },
                    });
                  });
                })
                .catch((error) =>
                {
                  //console.log(error)

                  let message = "Ocurrió un error al intentar iniciar session."

                  if (error?.response?.data?.message)
                  {
                    message = error.response.data.message
                  }


                  self.$refs.loginForm.setErrors({
                    "email": message,
                    "password": message
                  });

                  self.password = ""

                  self.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title: `Error`,
                      icon: "CoffeeIcon",
                      variant: "danger",
                      text: `${message}`,
                    },
                  });
                });

                

            });
          });





        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
